import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { cursoservice } from 'app/servicios/cursos.service';
import { programasservice } from 'app/servicios/programas.service';

@Component({
  selector: 'app-programas',
  templateUrl: './programas.component.html',
  styleUrls: ['./programas.component.scss']
})
export class ProgramasComponent implements OnInit {
  displayedColumns: string[] = ['idPrograma', 'nombre','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public centro_investigacion: any = [];

  constructor(private servicioprogramas: programasservice,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerCursos()
  }

  obtenerCursos = () =>{
    this.servicioprogramas.getProgramas().subscribe(response =>{
      this.centro_investigacion = response;
      this.dataSource = new MatTableDataSource(this.centro_investigacion);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarCurso = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioprogramas.eliminarProgramas(id).subscribe(response =>{
          this.obtenerCursos()
        })
      }
  
    })
  }

}
