import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { LbdModule } from '../../lbd/lbd.module';
import { NguiMapModule } from '@ngui/map';

import { AdminLayoutRoutes } from './admin-layout.routing';

//CODHEY
import { SedesComponent } from '../../sedes/sedes.component';
import { SedeComponent } from '../../sedes/sede/sede.component';
import { AntecendentesComponent } from 'app/codhey/antecendentes/antecendentes.component';
import { AtribucionesComponent } from 'app/codhey/atribuciones/atribuciones.component';
import { OrganigramaComponent } from 'app/codhey/organigrama/organigrama.component';
import { OrganigramaDetalleComponent } from 'app/codhey/organigrama/organigrama-detalle/organigrama-detalle.component';
import { OrganigramaDescripcionComponent } from 'app/codhey/organigrama/organigrama-descripcion/organigrama-descripcion.component';
import { ConsejoConsultivoComponent } from 'app/codhey/consejo-consultivo/consejo-consultivo.component';
import { ConsejoConsultivoVerComponent } from 'app/codhey/consejo-consultivo/consejo-consultivo-ver/consejo-consultivo-ver/consejo-consultivo-ver.component';
import { MensajePresidenteComponent } from 'app/codhey/mensaje-presidente/mensaje-presidente.component';
import { NormatividadComponent } from 'app/codhey/normatividad/normatividad.component';
import { NormatividadVerComponent } from 'app/codhey/normatividad/normatividad-ver/normatividad-ver.component';

//DERECHOS HUMANOS
import { OrganismosNacionalesInternacionalesComponent } from 'app/derechos-humanos/organismos-nacionales-internacionales/organismos-nacionales-internacionales.component';
import { OrganismosNalIntlDetalleComponent } from 'app/derechos-humanos/organismos-nacionales-internacionales/organismos-nal-intl-detalle/organismos-nal-intl-detalle.component';
import { CentroInvestigacionComponent } from 'app/derechos-humanos/centro-investigacion/centro-investigacion.component';
import { CentroInvestigacionDetalleComponent } from 'app/derechos-humanos/centro-investigacion/centro-investigacion-detalle/centro-investigacion-detalle.component';
import { SupervisionPermanenteComponent } from 'app/derechos-humanos/supervision-permanente/supervision-permanente.component';
import { SupervisionPermanenteDetalleComponent } from 'app/derechos-humanos/supervision-permanente/supervision-permanente-detalle/supervision-permanente-detalle.component';
import { SupervisionPermanenteDHComponent } from 'app/derechos-humanos/defensa-proteccion/supervision-permanente-dh/supervision-permanente-dh.component';
import { ConciliacionesComponent } from 'app/derechos-humanos/defensa-proteccion/conciliaciones/conciliaciones.component';
import { MedidasCautelaresComponent } from 'app/derechos-humanos/defensa-proteccion/medidas-cautelares/medidas-cautelares.component';
import { ConvenioColaboracionComponent } from 'app/derechos-humanos/divulgacion-promocion/convenio-colaboracion/convenio-colaboracion.component';
import { CapacitacionComponent } from 'app/derechos-humanos/divulgacion-promocion/capacitacion/capacitacion.component';
import { MecanismoComponent } from 'app/derechos-humanos/centro-investigacion/mecanismo/mecanismo.component';
import { UnidadInvestigacionComponent } from 'app/derechos-humanos/centro-investigacion/unidad-investigacion/unidad-investigacion.component';
import { UnidadDocumentacionComponent } from 'app/derechos-humanos/centro-investigacion/unidad-documentacion/unidad-documentacion.component';
import { UnidadProfesionalismoComponent } from 'app/derechos-humanos/centro-investigacion/unidad-profesionalismo/unidad-profesionalismo.component';
import { DescripcionDerechosHComponent } from 'app/derechos-humanos/descripcion-derechos-h/descripcion-derechos-h.component';
import { SubmodulosComponent } from 'app/derechos-humanos/centro-investigacion/submodulos/submodulos.component';
import { SubmodulosDetalleComponent } from 'app/derechos-humanos/centro-investigacion/submodulos/submodulos-detalle/submodulos-detalle.component';


//RECOMENDACIONES
import { RecomendacionComponent } from 'app/recomendaciones/recomendacion/recomendacion.component';
import { RecomendacionDetalleComponent } from 'app/recomendaciones/recomendacion/recomendacion-detalle/recomendacion-detalle.component';
import { RecomendacionGeneralComponent } from 'app/recomendaciones/recomendacion-general/recomendacion-general.component';
import { RecomendacionGeneralDetalleComponent } from 'app/recomendaciones/recomendacion-general/recomendacion-general-detalle/recomendacion-general-detalle.component';
import { AcuerdoNoResponsComponent } from 'app/recomendaciones/acuerdo-no-respons/acuerdo-no-respons.component';
import { AcuerdoNoResponsDetalleComponent } from 'app/recomendaciones/acuerdo-no-respons/acuerdo-no-respons-detalle/acuerdo-no-respons-detalle.component';
import { InconstitucionalidadComponent } from 'app/recomendaciones/inconstitucionalidad/inconstitucionalidad.component';
import { ImagenesComponent } from 'app/recomendaciones/imagenes/imagenes.component';
import { InconstitucionalidadDetalleComponent } from 'app/recomendaciones/inconstitucionalidad/inconstitucionalidad-detalle/inconstitucionalidad-detalle.component';
import { SeguimientosComponent } from 'app/recomendaciones/seguimientos/seguimientos.component';
import { InformesEspecialesComponent } from 'app/recomendaciones/informes-especiales/informes-especiales.component';


//DIFUSIÓN
import { ConcursosComponent } from 'app/difusion/concursos/concursos.component';
import { ConcursoComponent } from 'app/difusion/concursos/concurso/concurso.component';
import { BoletinesPrensaComponent } from 'app/difusion/boletines-prensa/boletines-prensa.component';
import { BoletinPrensaComponent } from 'app/difusion/boletines-prensa/boletin-prensa/boletin-prensa.component';
import { ProgramasRadioComponent } from 'app/difusion/programas-radio/programas-radio.component';
import { ProgramaRadioDetalleComponent } from 'app/difusion/programas-radio/programa-radio-detalle/programa-radio-detalle.component';
import { GaleriaFotograficaComponent } from 'app/difusion/galeria-fotografica/galeria-fotografica.component';
import { GaleriaDetalleComponent } from 'app/difusion/galeria-fotografica/galeria-detalle/galeria-detalle.component';
import { PublicacionesComponent } from 'app/difusion/publicaciones/publicaciones.component';
import { PublicacionesDetalleComponent } from 'app/difusion/publicaciones/publicaciones-detalle/publicaciones-detalle.component';
import { VideosComponent } from 'app/difusion/videos/videos.component';
import { VideoDetalleComponent } from 'app/difusion/videos/video-detalle/video-detalle.component';
import { CatalogoVideosComponent } from 'app/catalogos/catalogo-videos/catalogo-videos.component';
import { CatalogoVideoDetalleComponent } from 'app/catalogos/catalogo-videos/catalogo-video-detalle/catalogo-video-detalle.component';


//DESTACADOS
import { ConvocatoriasComponent } from 'app/destacados/convocatorias/convocatorias.component';
import { ConvocatoriaDetalleComponent } from 'app/destacados/convocatorias/convocatoria-detalle/convocatoria-detalle.component';
import { CursosComponent } from 'app/destacados/cursos/cursos.component';
import { CursoDetalleComponent } from 'app/destacados/cursos/curso-detalle/curso-detalle.component';
import { DescripcionCursosComponent } from 'app/destacados/descripcion-cursos/descripcion-cursos.component';
import { BibliotecaComponent } from 'app/destacados/biblioteca/biblioteca.component';
import { BibliotecaDetalleComponent } from 'app/destacados/biblioteca/biblioteca-detalle/biblioteca-detalle.component';
import { ArmonizacionComponent } from 'app/destacados/sistema-control-interno/armonizacion/armonizacion.component';
import { ArmonizacionDetalleComponent } from 'app/destacados/sistema-control-interno/armonizacion/armonizacion-detalle/armonizacion-detalle.component';
import { QuienesSomosComponent } from 'app/destacados/organo-control-interno/quienes-somos/quienes-somos.component';
import { DeclaracionesPatrimonialesComponent } from 'app/destacados/organo-control-interno/declaraciones-patrimoniales/declaraciones-patrimoniales.component';
import { EntregaRecepcionComponent } from 'app/destacados/organo-control-interno/entrega-recepcion/entrega-recepcion.component';
import { NormatividadOrganoComponent } from 'app/destacados/organo-control-interno/normatividad-organo/normatividad-organo.component';
import { PresentaDenunciaComponent } from 'app/destacados/organo-control-interno/presenta-denuncia/presenta-denuncia.component';
import { TransparenciaComponent } from 'app/destacados/transparencia/transparencia.component';
import { ControlInternoComponent } from 'app/destacados/sistema-control-interno/control-interno/control-interno.component';
import { GruposVulnerablesComponent } from 'app/destacados/grupos-vulnerables/grupos-vulnerables.component';
import { GruposVulnerablesDetalleComponent } from 'app/destacados/grupos-vulnerables/grupos-vulnerables-detalle/grupos-vulnerables-detalle.component';
import { ImagenesDestacadasComponent } from 'app/destacados/imagenes-destacadas/imagenes-destacadas.component';
import { DescripcionArmonizacionComponent } from 'app/destacados/descripcion-armonizacion/descripcion-armonizacion.component';
import { DescripcionSciComponent } from 'app/destacados/sistema-control-interno/descripcion-sci/descripcion-sci.component';
import { ContenidoGruposComponent } from 'app/destacados/grupos-vulnerables/contenido-grupos/contenido-grupos.component';
import { SubmodulosSciComponent } from 'app/destacados/sistema-control-interno/submodulos-sci/submodulos-sci.component';
import { SubmodulosSciDetalleComponent } from 'app/destacados/sistema-control-interno/submodulos-sci/submodulos-sci-detalle/submodulos-sci-detalle.component';
import { SubmodulosGruposComponent } from 'app/destacados/grupos-vulnerables/submodulos-grupos/submodulos-grupos.component';
import { SubmodulosGruposDetalleComponent } from 'app/destacados/grupos-vulnerables/submodulos-grupos/submodulos-grupos-detalle/submodulos-grupos-detalle.component';

//CATALOGOS
import { NormatividadCatalogoComponent } from 'app/catalogos/normatividad-catalogo/normatividad-catalogo.component';
import { CatalogoDetalleNormatividadComponent } from 'app/catalogos/normatividad-catalogo/catalogo-detalle-normatividad/catalogo-detalle-normatividad.component';
import { CatalogoPublicacionesComponent } from 'app/catalogos/catalogo-publicaciones/catalogo-publicaciones.component';
import { CatalogoDetallePublicacionesComponent } from 'app/catalogos/catalogo-publicaciones/catalogo-detalle-publicaciones/catalogo-detalle-publicaciones.component';
import { CatalogoOrganismosComponent } from 'app/catalogos/catalogo-organismos/catalogo-organismos.component';
import { CatalogoOrganismoDetalleComponent } from 'app/catalogos/catalogo-organismos/catalogo-organismo-detalle/catalogo-organismo-detalle.component';
import { CatalogoAutoresComponent } from 'app/catalogos/catalogo-autores/catalogo-autores.component';
import { CatalogoAutoresDetalleComponent } from 'app/catalogos/catalogo-autores/catalogo-autores-detalle/catalogo-autores-detalle.component';
import { CatalogoArmonizacionEncabezadoComponent } from 'app/catalogos/catalogo-armonizacion-encabezado/catalogo-armonizacion-encabezado.component';
import { ArmonizacionEncabezadoEditarComponent } from 'app/catalogos/catalogo-armonizacion-encabezado/armonizacion-encabezado-editar/armonizacion-encabezado-editar.component';
import { CatalogoArmonizacionDetalleComponent } from 'app/catalogos/catalogo-armonizacion-detalle/catalogo-armonizacion-detalle.component';
import { ArmonizacionDetalleEditarComponent } from 'app/catalogos/catalogo-armonizacion-detalle/armonizacion-detalle-editar/armonizacion-detalle-editar.component';
import { CatalogoGrupoVulnerableComponent } from 'app/catalogos/catalogo-grupo-vulnerable/catalogo-grupo-vulnerable.component';
import { CatalogoGrupoVulnerableDetalleComponent } from 'app/catalogos/catalogo-grupo-vulnerable/catalogo-grupo-vulnerable-detalle/catalogo-grupo-vulnerable-detalle.component';
import { CatalogoJuegosComponent } from 'app/catalogos/catalogo-juegos/catalogo-juegos.component';
import { CatalogoJuegosDetalleComponent } from 'app/catalogos/catalogo-juegos/catalogo-juegos-detalle/catalogo-juegos-detalle.component';
import { CatalogoPuestoNinosDetalleComponent } from 'app/catalogos/catalogo-puesto-ninos/catalogo-puesto-ninos-detalle/catalogo-puesto-ninos-detalle.component';
import { CatalogoPuestoNinosComponent } from 'app/catalogos/catalogo-puesto-ninos/catalogo-puesto-ninos.component';
import { CatalogoMicrositiosComponent } from 'app/catalogos/catalogo-micrositios/catalogo-micrositios.component';
import { CatalogoMicrositiosDetalleComponent } from 'app/catalogos/catalogo-micrositios/catalogo-micrositios-detalle/catalogo-micrositios-detalle.component';
import { CatalogoNinosCatComponent } from 'app/catalogos/catalogo-ninos-cat/catalogo-ninos-cat.component';
import { CatalogoNinosCatDetalleComponent } from 'app/catalogos/catalogo-ninos-cat/catalogo-ninos-cat-detalle/catalogo-ninos-cat-detalle.component';
import { FechasComponent } from 'app/catalogos/fechas/fechas.component';
import { FechasDetalleComponent } from 'app/catalogos/fechas/fechas-detalle/fechas-detalle.component';
import { CatalogoUnidadInvComponent } from 'app/catalogos/catalogo-unidad-inv/catalogo-unidad-inv.component';
import { CatalogoUnidadDetalleComponent } from 'app/catalogos/catalogo-unidad-inv/catalogo-unidad-detalle/catalogo-unidad-detalle.component';
import { CatalogoSciComponent } from 'app/catalogos/catalogo-sci/catalogo-sci.component';
import { CatalogoSciDetalleComponent } from 'app/catalogos/catalogo-sci/catalogo-sci-detalle/catalogo-sci-detalle.component';
import { CatalogoConcursosComponent } from 'app/catalogos/catalogo-concursos/catalogo-concursos.component';
import { CatalogoConcursosDetalleComponent } from 'app/catalogos/catalogo-concursos/catalogo-concursos-detalle/catalogo-concursos-detalle.component';
import { CatalogoSubmodulosGruposComponent } from 'app/catalogos/catalogo-submodulos-grupos/catalogo-submodulos-grupos.component';
import { CatalogoSubmodulosGruposDetalleComponent } from 'app/catalogos/catalogo-submodulos-grupos/catalogo-submodulos-grupos-detalle/catalogo-submodulos-grupos-detalle.component';

//AVISOS DE PRIVACIDAD
import { AvisoPrivacidadComponent } from 'app/aviso-privacidad/aviso-privacidad.component';
import { AvisoPrivacidadDetalleComponent } from 'app/aviso-privacidad/aviso-privacidad-detalle/aviso-privacidad-detalle.component';

//SECCIÓN NIÑOS
import { EscribenosComponent } from 'app/seccion-ninios/escribenos/escribenos.component';
import { JuegosComponent } from 'app/seccion-ninios/juegos/juegos.component';
import { JuegosDetalleComponent } from 'app/seccion-ninios/juegos/juegos-detalle/juegos-detalle.component';
import { AudioDescripcionComponent } from 'app/seccion-ninios/audios/audio-descripcion/audio-descripcion.component';
import { AudiosNiniosComponent } from 'app/seccion-ninios/audios/audios-ninios/audios-ninios.component';
import { DescripcionCuentosComponent } from 'app/seccion-ninios/cuentos/descripcion-cuentos/descripcion-cuentos.component';
import { ConcursosNinosComponent } from 'app/seccion-ninios/cuentos/concursos-ninos/concursos-ninos.component';
import { ConcursosNinosDetalleComponent } from 'app/seccion-ninios/cuentos/concursos-ninos/concursos-ninos-detalle/concursos-ninos-detalle.component';
import { ConoceDerechosPdfComponent } from 'app/seccion-ninios/conoce-derechos/conoce-derechos-pdf/conoce-derechos-pdf.component';
import { ConoceDerechosPdfDetalleComponent } from 'app/seccion-ninios/conoce-derechos/conoce-derechos-pdf/conoce-derechos-pdf-detalle/conoce-derechos-pdf-detalle.component';
import { DescripcionDerechosComponent } from 'app/seccion-ninios/conoce-derechos/descripcion-derechos/descripcion-derechos.component';
import { DescripcionDerechosDetalleComponent } from 'app/seccion-ninios/conoce-derechos/descripcion-derechos/descripcion-derechos-detalle/descripcion-derechos-detalle.component';

//MICROSITIOS
import { MicrositiosComponent } from 'app/micrositios/micrositios/micrositios.component';
import { MicrositiosDetalleComponent } from 'app/micrositios/micrositios/micrositios-detalle/micrositios-detalle.component';

//CONFIGURACION
import { GruposPermisosComponent } from 'app/configuracion/grupos-permisos/grupos-permisos.component';
import { GruposPermisosDetalleComponent } from 'app/configuracion/grupos-permisos/grupos-permisos-detalle/grupos-permisos-detalle.component';
import { UsuariosComponent } from 'app/configuracion/usuarios/usuarios.component';
import { UsuariosDetalleComponent } from 'app/configuracion/usuarios/usuarios-detalle/usuarios-detalle.component';

//QUEJAS
import { QuejasComponent } from 'app/quejas/quejas.component';
import { QuejasDetalleComponent } from 'app/quejas/quejas-detalle/quejas-detalle.component';


//ANGULAR MATERIAL
import { HomeComponent } from '../../home/home.component';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSliderModule } from '@angular/material/slider';
import { MatExpansionModule } from '@angular/material/expansion';

//COMPONENTES PERSONALIZADOS
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { CuadroConfirmacionComponent } from 'app/componentes/cuadro-confirmacion/cuadro-confirmacion.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { SafeHtmlPipe } from 'app/compartido/pipe/safeHtml.pipe';
import { VideosNiniosComponent } from 'app/seccion-ninios/audios/videos-ninios/videos-ninios.component';
import { VideoNinioDetalleComponent } from 'app/seccion-ninios/audios/videos-ninios/video-ninio-detalle/video-ninio-detalle.component';
import { ProgramasComponent } from 'app/destacados/programas/programas.component';
import { ProgramaDetalleComponent } from 'app/destacados/programas/curso-detalle/programa-detalle.component';
import { DescripcionProgramasComponent } from 'app/destacados/descripcion-programas/descripcion-programas.component';


@NgModule({
  imports: [
    CommonModule,
    CKEditorModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    ReactiveFormsModule,
    LbdModule,
    NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=YOUR_KEY_HERE' }),
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    MatExpansionModule,
    MatSliderModule,
    MaterialFileInputModule
  ],
  declarations: [

    //CODHEY
    SedesComponent,
    SedeComponent,
    MensajePresidenteComponent,
    ConsejoConsultivoComponent,
    ConsejoConsultivoVerComponent,
    NormatividadComponent,
    NormatividadVerComponent,
    NormatividadCatalogoComponent,
    OrganigramaComponent,
    OrganigramaDetalleComponent,
    OrganigramaDescripcionComponent,
    AntecendentesComponent,
    AtribucionesComponent,

    //DERECHOS HUMANOS
    OrganismosNacionalesInternacionalesComponent,
    OrganismosNalIntlDetalleComponent,
    CentroInvestigacionComponent,
    CentroInvestigacionDetalleComponent,
    SupervisionPermanenteComponent,
    SupervisionPermanenteDetalleComponent,
    SupervisionPermanenteDHComponent,
    ConciliacionesComponent,
    MedidasCautelaresComponent,
    ConvenioColaboracionComponent,
    MecanismoComponent,
    UnidadInvestigacionComponent,
    UnidadDocumentacionComponent,
    UnidadProfesionalismoComponent,
    DescripcionDerechosHComponent,
    CapacitacionComponent,
    SubmodulosComponent,
    SubmodulosDetalleComponent,

    //RECOMENDACIONES
    RecomendacionComponent,
    RecomendacionDetalleComponent,
    RecomendacionGeneralComponent,
    RecomendacionGeneralDetalleComponent,
    AcuerdoNoResponsComponent,
    AcuerdoNoResponsDetalleComponent,
    InconstitucionalidadComponent,
    InconstitucionalidadDetalleComponent,
    SeguimientosComponent,
    ImagenesComponent,
    InformesEspecialesComponent,

    //DIFUSIÓN
    ConcursosComponent,
    ConcursoComponent,
    BoletinesPrensaComponent,
    BoletinPrensaComponent,
    ProgramasRadioComponent,
    ProgramaRadioDetalleComponent,
    GaleriaFotograficaComponent,
    GaleriaDetalleComponent,
    PublicacionesComponent,
    PublicacionesDetalleComponent,
    GruposVulnerablesComponent,
    GruposVulnerablesDetalleComponent,
    TransparenciaComponent,
    ImagenesDestacadasComponent,
    ContenidoGruposComponent,
    DescripcionArmonizacionComponent,
    DescripcionSciComponent,
    SubmodulosSciComponent,
    SubmodulosSciDetalleComponent,
    SubmodulosGruposComponent,
    SubmodulosGruposDetalleComponent,


    //AVISO DE PRIVACIDAD
    AvisoPrivacidadComponent,
    AvisoPrivacidadDetalleComponent,

    //DIFUSIÓN
    ConvocatoriasComponent,
    ConvocatoriaDetalleComponent,
    CursosComponent,
    CursoDetalleComponent,
    VideosComponent,
    VideoDetalleComponent,
    CatalogoVideosComponent,
    CatalogoVideoDetalleComponent,

    //DESTACADOS
    ArmonizacionEncabezadoEditarComponent,
    ArmonizacionDetalleEditarComponent,
    ArmonizacionComponent,
    ArmonizacionDetalleComponent,
    DescripcionCursosComponent,
    DescripcionProgramasComponent,
    BibliotecaComponent,
    BibliotecaDetalleComponent,
    QuienesSomosComponent,
    DeclaracionesPatrimonialesComponent,
    EntregaRecepcionComponent,
    NormatividadOrganoComponent,
    PresentaDenunciaComponent,
    ControlInternoComponent,
    ProgramasComponent,
    ProgramaDetalleComponent,

    //OTROS
    HomeComponent,

    //COMPONENTES CREADOS
    CuadroDialogoComponent,
    CuadroConfirmacionComponent,
    MatDialogComponent,
    SafeHtmlPipe,

    //CATALOGOS
    CatalogoDetalleNormatividadComponent,
    CatalogoPublicacionesComponent,
    CatalogoDetallePublicacionesComponent,
    CatalogoOrganismosComponent,
    CatalogoOrganismoDetalleComponent,
    CatalogoAutoresComponent,
    CatalogoAutoresDetalleComponent,
    CatalogoArmonizacionEncabezadoComponent,
    CatalogoArmonizacionDetalleComponent,
    CatalogoGrupoVulnerableComponent,
    CatalogoGrupoVulnerableDetalleComponent,
    CatalogoJuegosComponent,
    CatalogoJuegosDetalleComponent,
    CatalogoPuestoNinosComponent,
    CatalogoPuestoNinosDetalleComponent,
    CatalogoMicrositiosComponent,
    CatalogoMicrositiosDetalleComponent,
    CatalogoNinosCatComponent,
    CatalogoNinosCatDetalleComponent,
    CatalogoUnidadInvComponent,
    CatalogoUnidadDetalleComponent,
    CatalogoConcursosComponent,
    CatalogoConcursosDetalleComponent,
    CatalogoSubmodulosGruposComponent,
    CatalogoSubmodulosGruposDetalleComponent,
    CatalogoSciComponent,
    CatalogoSciDetalleComponent,
    FechasComponent,
    FechasDetalleComponent,

    //MICROSITIOS
    MicrositiosComponent,
    MicrositiosDetalleComponent,

    //SECCION NIÑOS
    EscribenosComponent,
    JuegosComponent,
    JuegosDetalleComponent,
    AudioDescripcionComponent,
    AudiosNiniosComponent,
    DescripcionCuentosComponent,
    ConcursosNinosComponent,
    ConcursosNinosDetalleComponent,
    ConoceDerechosPdfComponent,
    ConoceDerechosPdfDetalleComponent,
    DescripcionDerechosComponent,
    DescripcionDerechosDetalleComponent,
    VideosNiniosComponent,
    VideoNinioDetalleComponent,

    //CONFIGURACIÓN
    GruposPermisosComponent,
    GruposPermisosDetalleComponent,
    UsuariosComponent,
    UsuariosDetalleComponent,

    //QUEJAS
    QuejasComponent,
    QuejasDetalleComponent
  ],
  exports: [
    SafeHtmlPipe
  ]
})

export class AdminLayoutModule { }
