import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { programasservice } from 'app/servicios/programas.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-programa-detalle',
  templateUrl: './programa-detalle.component.html',
  styleUrls: ['./programa-detalle.component.scss']
})
export class ProgramaDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idPrograma: string = this.obtenerId().idPrograma;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  cursoImagen: any = '';
  ganadores: any = []
  imagenes: any = [];


  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  CursoForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    descripcion: new FormControl(''),
    estado: new FormControl(1),
    rutaImagen: new FormControl('')
  });


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciocurso: programasservice,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idPrograma) {
      this.serviciocurso.getPrograma(this.idPrograma).subscribe(response => {
        this.CursoForm.patchValue(response[0])
        this.cursoImagen = response[0].rutaImagen;
      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.CursoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //VERIFICAMOS LOS CAMBIOS EN LOS INPUTS DEL FORMULARIO
  onChangeEvent(event: any) {
    if (this.idPrograma) {
      this.edit = false;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    let result: any = {};
    let id;
    let imagen = this.CursoForm.get('rutaImagen').value;
    if (this.CursoForm.get('rutaImagen').value) {
      this.CursoForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.serviciocurso.crearProgramas(this.CursoForm.value).subscribe(response => {
      result = response;
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      if (this.CursoForm.get('rutaImagen').value) {
        this.guardarImagen(imagen)
      }

      this.CursoForm.markAsPristine()
      this.CursoForm.markAllAsTouched()
      // this.CursoForm.reset();
      this.router.navigateByUrl('/programas')
    });
  }

  actualizarDatos = () => {
    let imagen = this.CursoForm.get('rutaImagen').value;
    if (this.CursoForm.get('rutaImagen').value) {
      this.CursoForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.serviciocurso.actualizarProgramas(this.idPrograma, this.CursoForm.value).subscribe(response => {
      if (this.CursoForm.get('rutaImagen').value) {
        this.guardarImagen(imagen)
      }
      
      this.CursoForm.markAsPristine()
      this.CursoForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/programas')
    });
  }

  guardarImagen = (imagen: any) => {

    imagen._files.forEach(element => {
      this.CursoForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.CursoForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });

  }

  eliminarImagen = () =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciocurso.actualizarEstadoImagen(this.idPrograma, this.CursoForm.value).subscribe(response =>{
          this.serviciodocumento.eliminarImagen(this.cursoImagen).subscribe(response =>{

          })
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        });
      }
  
    })
  }

}
